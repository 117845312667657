import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import KnowledgeBaseLayout from '../layouts/KnowledgeBaseLayout'


const IndexPage : React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      page: allFile(filter: {relativePath: {eq: "index.md"}}) {
        nodes {
          name
          childMarkdownRemark {
            html
          }
        }
      }
    }`
  )

  const html = data.page.nodes[0].childMarkdownRemark.html
  return (
    <KnowledgeBaseLayout>
        <main dangerouslySetInnerHTML={{__html: html}} />
    </KnowledgeBaseLayout>
  )
}


export default IndexPage
